import { Block, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSchoolSetting, getSEO } from 'utils';

/**
 * Path: /politica-de-privacidad
 * Key: PRIVACY_POLICY
 * @returns {React.ReactElement}
 */
export default function PrivacyPolicyPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.privacyPolicy.seo', true)}

      <Block narrow>
        <Col xs={12} md={8} offset={{ md: 2 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: getSchoolSetting('privacyPolicy')
            }}
          />
        </Col>
      </Block>
    </PageWrapper>
  );
}
