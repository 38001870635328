import { addTranslation, setDefaultTranslation } from 'dawere-commons';
import { addUITranslations } from 'dawere-uic';
import { en, es } from 'i18n';

export function i18nSetup() {
  setDefaultTranslation({ v2: es });

  addUITranslations();

  // Base languages
  addTranslation({ v2: es }, es.lang);
  addTranslation({ v2: en }, en.lang);
}
