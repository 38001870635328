import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Fab, Icons, Tooltip, Utils, Visible } from 'dawere-uic';
import { useEffect, useState } from 'react';

export function WhatsappFabButton({ link }: any) {
  const account = Session.getAccountInfo();
  const [hasEnrolments, setHasEnrolments] = useState<boolean>(false);
  const [allTrials, setAllTrials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // If we have a session, we get the programs and set program status
    if (account?.id) {
      setIsLoading(true);

      API.Students.getAllEnrollments({ latest: true })
        .then((data: any = []) => {
          setHasEnrolments(!Utils.isEmpty(data));
          setAllTrials(
            data.every((e: any) =>
              ['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(e.status)
            )
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [account?.id]);

  if ((hasEnrolments && !allTrials) || isLoading) {
    // If we have a session check if we have programs
    return null;
  }

  // If we have a session check if is an student
  if (account && !account?.student) {
    return null;
  }

  return (
    <>
      <Visible xs sm md>
        <Fab
          loading={isLoading}
          className='bg-success'
          href={link}
          target='_blank'>
          <Tooltip
            text={i18n('v2.pages.home.info.whatsappText')}
            placement='left'>
            <span>
              <Icons.Whatsapp />
            </span>
          </Tooltip>
        </Fab>
      </Visible>

      <Visible lg xl>
        <Fab
          loading={isLoading}
          className='bg-success rounded-pill'
          circle={false}
          href={link}
          target='_blank'>
          <Icons.Whatsapp className='fs-1' />{' '}
          <small>{i18n('v2.pages.home.info.whatsappText')}</small>
        </Fab>
      </Visible>
    </>
  );
}
