import { API } from 'api';
import { i18n, Session, setDefaultTranslation } from 'dawere-commons';
import { Dawere, useRouter, Utils } from 'dawere-uic';
import { useLayout, useSession } from 'hooks';
import { en, es } from 'i18n';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import {
  COUNTRIES_LIST,
  getDashboardPath,
  getFullCountryInfo,
  getSchoolSetting,
  updateCountryInUrl
} from 'utils';

export function Sidebar() {
  const { setUser } = useSession();

  const account = Session.getAccountInfo();
  const organizations = Session.getOrganizations();
  const currentCountry = getFullCountryInfo();

  const { push } = useRouter();
  const { sidebarOpened, setSidebarOpened } = useLayout();

  const handleCountryChange = useCallback((data: any) => {
    API.Account.updateLocale().finally(() => updateCountryInUrl());
  }, []);

  const handleLanguageChange = useCallback((lang: string) => {
    setDefaultTranslation({ v2: lang === 'es' ? es : en });

    API.Account.updateLocale().finally(() => window.location.reload());
  }, []);

  const handleLogout = useCallback(() => {
    Session.clean();
    setUser(null);
    push(PATHS.HOME);
  }, [push, setUser]);

  if (account) {
    return (
      <Dawere.Sidebar open={sidebarOpened} onClose={setSidebarOpened}>
        <Dawere.Sidebar.Profile
          avatar={account?.picturePath}
          name={Utils.getFirstPart(account?.name)}
          role={account?.lastName}
        />

        <Dawere.Sidebar.Divider />

        <Dawere.Sidebar.Item
          hidden={!account?.student}
          to={PATHS.STUDENTS_DASHBOARD}>
          {i18n('v2.sections.navbar.links.studentDashboard')}
        </Dawere.Sidebar.Item>

        <Dawere.Sidebar.Item
          hidden={!account?.tutor}
          to={PATHS.TUTORS_DASHBOARD}>
          {i18n('v2.sections.navbar.links.parentDashboard')}
        </Dawere.Sidebar.Item>

        {organizations.map((organization: any) => (
          <Dawere.Sidebar.Item
            key={organization.id}
            hidden={organizations.length > 3}
            to={getDashboardPath(organization)}>
            {i18n('v2.sections.navbar.links.dashboardOf', {
              name: organization.name
            })}
          </Dawere.Sidebar.Item>
        ))}

        <Dawere.Sidebar.Divider
          hidden={
            !(
              account?.student ||
              account?.tutor ||
              (organizations.length <= 3 && organizations.length > 0)
            )
          }
        />

        {/* <Dawere.Sidebar.Divider
          hidden={organizations.length === 0 || organizations.length > 3}
        /> */}

        <Dawere.Sidebar.Item
          to={PATHS.MY_ORGANIZATIONS}
          hidden={!account?.organization}>
          {i18n('v2.sections.navbar.links.myOrganizations')}
          <span className='rounded bg-powderTint px-2 fs-small d-inline-block ml-2'>
            {organizations.length}
          </span>
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.PROFILE}>
          {i18n('v2.sections.navbar.links.profile')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.SETTINGS}>
          {i18n('v2.sections.navbar.links.settings')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.PERMISSIONS}>
          {i18n('v2.sections.navbar.links.permissions')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.WALLET}>
          {i18n('v2.sections.navbar.links.wallet')}
        </Dawere.Sidebar.Item>

        <Dawere.Sidebar.Divider />

        <Dawere.Sidebar.Item to={PATHS.HOME}>
          {i18n('v2.sections.navbar.links.home')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.CATALOG}>
          {i18n('v2.sections.navbar.links.catalog')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item to={PATHS.ORGANIZATIONS}>
          {i18n('v2.sections.navbar.links.organizations')}
        </Dawere.Sidebar.Item>
        <Dawere.Sidebar.Item onClick={() => API.Zendesk.go()}>
          {i18n('v2.sections.navbar.links.help')}
        </Dawere.Sidebar.Item>

        <Dawere.Sidebar.Divider />

        <Dawere.Sidebar.Item onClick={handleLogout}>
          {i18n('v2.sections.navbar.links.closeSession')}
        </Dawere.Sidebar.Item>

        <Dawere.Sidebar.Divider />

        <Dawere.CountryPicker
          variant='sidebar'
          countries={COUNTRIES_LIST}
          onChangeCountry={handleCountryChange}
          onChangeLanguage={handleLanguageChange}
        />
      </Dawere.Sidebar>
    );
  }

  return (
    <Dawere.Sidebar open={sidebarOpened} onClose={setSidebarOpened}>
      <Dawere.Sidebar.Logo {...getSchoolSetting('settings')?.logos} />

      <Dawere.Sidebar.Divider />

      {/* <Dawere.Sidebar.Item to={PATHS.CATALOG}>
        {i18n('v2.sections.navbar.links.catalog')}
      </Dawere.Sidebar.Item>
      <Dawere.Sidebar.Item to={PATHS.ORGANIZATIONS}>
        {i18n('v2.sections.navbar.links.organizations')}
      </Dawere.Sidebar.Item>

      <Dawere.Sidebar.Divider /> */}

      <Dawere.Sidebar.Item to={PATHS.HOME}>
        {i18n('v2.sections.navbar.links.home')}
      </Dawere.Sidebar.Item>
      <Dawere.Sidebar.Item onClick={() => API.Zendesk.go()}>
        {i18n('v2.sections.navbar.links.help')}
      </Dawere.Sidebar.Item>

      <Dawere.Sidebar.Divider />

      <Dawere.Sidebar.Button variant='outline' as={Link} to={PATHS.LOGIN}>
        {i18n('v2.sections.navbar.buttons.login')}
      </Dawere.Sidebar.Button>
      <Dawere.Sidebar.Button
        variant='primary'
        className='click_register'
        as={Link}
        to={PATHS.REGISTER}>
        {i18n('v2.sections.navbar.buttons.register')}
      </Dawere.Sidebar.Button>

      {/* <Dawere.Sidebar.Divider />

      <Dawere.CountryPicker
        variant='sidebar'
        countries={COUNTRIES_LIST}
        onChangeCountry={handleCountryChange}
        onChangeLanguage={handleLanguageChange}
      /> */}
    </Dawere.Sidebar>
  );
}
