import { Facebook, Instagram, X, YouTube } from '@mui/icons-material';
import { API } from 'api';
import { i18n, Locales } from 'dawere-commons';
import { Col, Dawere, IBaseProps, Logo } from 'dawere-uic';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSchoolSetting, updateCountryInUrl } from 'utils';

export function Footer(props: IBaseProps) {
  const handleClick = useCallback((iso2: string) => {
    Locales.setCountry(iso2);
    API.Account.updateLocale().finally(() => updateCountryInUrl());
  }, []);

  return (
    <>
      <Dawere.Footer
        bottomSection={
          <Col className='text-md-center'>
            <Logo
              negative
              variant='FULL'
              size={200}
              {...getSchoolSetting('settings')?.logos}
            />
          </Col>
        }
        {...props}>
        <Col className='mb-6 mb-lg-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>{getSchoolSetting('name')}</h5>

          <p
            dangerouslySetInnerHTML={{
              __html: getSchoolSetting('footerDescription')
            }}
          />

          <small>
            © {new Date().getFullYear()} {getSchoolSetting('name')}
          </small>
        </Col>

        <Col className='mb-6 mb-lg-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column2.title')}
          </h5>
          <ul>
            {getSchoolSetting('manualUrl') && (
              <li>
                <a
                  href={getSchoolSetting('philosophyUrl')}
                  target='_blank'
                  rel='noreferrer'>
                  {i18n('v2.sections.footer.column2.links.1')}
                </a>
              </li>
            )}
            {getSchoolSetting('manualUrl') && (
              <li>
                <a
                  href={getSchoolSetting('manualUrl')}
                  target='_blank'
                  rel='noreferrer'>
                  {i18n('v2.sections.footer.column2.links.2')}
                </a>
              </li>
            )}
            {getSchoolSetting('termsAndConditions') && (
              <li>
                <Link to={PATHS.TERMS_AND_CONDITIONS}>
                  {i18n('v2.sections.footer.column2.links.3')}
                </Link>
              </li>
            )}
            {getSchoolSetting('privacyPolicy') && (
              <li>
                <Link to={PATHS.PRIVACY_POLICY}>
                  {i18n('v2.sections.footer.column2.links.4')}
                </Link>
              </li>
            )}
          </ul>
        </Col>

        <Col className='mb-6 mb-md-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column3.title')}
          </h5>
          <ul>
            {getSchoolSetting('helpdeskUrl') && (
              <li>
                <a
                  href={getSchoolSetting('helpdeskUrl')}
                  target='_blank'
                  rel='noreferrer'>
                  {i18n('v2.sections.footer.column3.links.1')}
                </a>
              </li>
            )}
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column4.title')}
          </h5>
          <ul>
            {getSchoolSetting('instagramLink') && (
              <li>
                <a
                  href={getSchoolSetting('instagramLink')}
                  target='_blank'
                  rel='noreferrer'>
                  <Instagram className='mr-2' fontSize='small' />
                  {i18n('v2.sections.footer.column4.links.2')}
                </a>
              </li>
            )}
            {getSchoolSetting('facebookLink') && (
              <li>
                <a
                  href={getSchoolSetting('facebookLink')}
                  target='_blank'
                  rel='noreferrer'>
                  <Facebook className='mr-2' fontSize='small' />
                  {i18n('v2.sections.footer.column4.links.3')}
                </a>
              </li>
            )}
            {getSchoolSetting('xlink') && (
              <li>
                <a
                  href={getSchoolSetting('xlink')}
                  target='_blank'
                  rel='noreferrer'>
                  <X className='mr-2' fontSize='small' />
                  {i18n('v2.sections.footer.column4.links.4')}
                </a>
              </li>
            )}
            {getSchoolSetting('youtubeLink') && (
              <li>
                <a
                  href={getSchoolSetting('youtubeLink')}
                  target='_blank'
                  rel='noreferrer'>
                  <YouTube className='mr-2' fontSize='small' />
                  {i18n('v2.sections.footer.column4.links.5')}
                </a>
              </li>
            )}
          </ul>
        </Col>
      </Dawere.Footer>
    </>
  );
}
