import { Refresh } from '@mui/icons-material';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Badge,
  Button,
  Col,
  Flex,
  Row,
  Table,
  Utils,
  Visible
} from 'dawere-uic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';

const AutoCompleteOption = ({ children, data, ...rest }: any) => {
  return (
    <AutoComplete.Option {...rest}>
      <span className='d-block'>{children}</span>
      <small className='d-block'>{data.description}</small>
    </AutoComplete.Option>
  );
};

export function InstallmentsTab({ orders = [], loading = true }: any) {
  const { rootRoute, studentId, getInstallments } = useStudentDashboard();

  const [order, setOrder] = useState<any>(null);
  const [installments, setInstallments] = useState<any[]>([]);
  const [isLoadingInstallments, setIsLoadingInstallments] = useState(false);
  const [payAll, setPayAll] = useState(false);
  const [havePending, setHavePending] = useState(false);

  const hasFailedInstallment = useMemo(() => {
    return installments.some((i: any) => i.status === 'FAILED');
  }, [installments]);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.status'
        ),
        accessor: 'status',
        collapse: true,
        Cell: ({ cell }: any) => {
          let status = cell.value;

          if (status === 'PENDING' && cell.row?.original?.due) {
            status = 'DUE';
          }

          const colors: any = {
            PAID: 'success',
            PENDING: 'muted',
            NOT_ALIVE: 'muted',
            FAILED: 'error',
            VOID: 'muted',
            DUE: 'warning',
            PROCESSING: 'warning'
          };

          return (
            <Badge pilled variant={colors[status]}>
              {i18n(`v2.pages.studentDashboard.tabs.4.tabs.2.${status}`)}
            </Badge>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.number'
        ),
        accessor: 'number',
        Cell: ({ cell }: any) => {
          return (
            <div>
              {i18n(
                'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.installment'
              )}{' '}
              #{cell.value}
            </div>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.dueDate'
        ),
        accessor: 'dueDate',
        Cell: ({ cell }: any) => {
          return (
            <>
              <span
                className={
                  cell.row?.original?.status === 'PENDING' &&
                  cell.row?.original?.due
                    ? 'text-error'
                    : ''
                }>
                {cell.value}
              </span>
            </>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.amount'
        ),
        accessor: 'totalAmt',
        Cell: ({ cell }: any) => {
          return (
            <>
              {order?.currency?.symbol || '$'}
              {Utils.toCurrency(cell.value)} ({order?.currency?.isoCode})
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (cell: any) => {
          const status = cell.row?.original?.status;

          if (status === 'PENDING') {
            return (
              <div className='text-right'>
                <Button
                  disabled={hasFailedInstallment}
                  variant={cell.row?.original?.due ? 'secondary' : 'primary'}
                  as={Link}
                  to={PATHS.get(
                    'PAY',
                    {
                      orderId: cell.row?.original?.id
                    },
                    {
                      [QPARAMS.Draft]: true,
                      [QPARAMS.Redirect]: encodeURIComponent(
                        PATHS.get(
                          rootRoute,
                          { studentId },
                          { [QPARAMS.Tab]: 3 }
                        )
                      )
                    }
                  )}>
                  {i18n('v2.pages.studentDashboard.tabs.4.tabs.2.buttons.pay')}
                </Button>
              </div>
            );
          }

          if (status === 'PAID') {
            return (
              <div className='text-right'>
                <Button
                  variant='outline'
                  as={Link}
                  target='_blank'
                  to={PATHS.get('RECEIPT', {
                    receiptId: cell.row?.original?.id
                  })}>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.buttons.download'
                  )}
                </Button>
              </div>
            );
          }

          return null;
        }
      }
    ],
    [
      hasFailedInstallment,
      order?.currency?.isoCode,
      order?.currency?.symbol,
      rootRoute,
      studentId
    ]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'number',
        collapse: true,
        Cell: ({ cell }: any) => {
          return <strong className='fs-1'>{cell.value}</strong>;
        }
      },
      {
        Header: i18n(
          'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.installment'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          let status = row.original?.status;

          if (status === 'PENDING' && row?.original?.due) {
            status = 'DUE';
          }

          const colors: any = {
            PAID: 'success',
            PENDING: 'muted',
            NOT_ALIVE: 'muted',
            FAILED: 'error',
            VOID: 'muted',
            DUE: 'warning',
            PROCESSING: 'warning'
          };

          return (
            <div>
              <Flex align='start' justify='between'>
                <div>
                  <small className='fw-500'>
                    {i18n(
                      'v2.pages.studentDashboard.tabs.4.tabs.2.table.columns.dueDate'
                    )}
                    :
                  </small>
                  <div
                    className={
                      row?.original?.status === 'PENDING' && row?.original?.due
                        ? 'text-error'
                        : ''
                    }>
                    {row?.original?.dueDate}
                  </div>
                </div>

                <div className='text-right'>
                  <div className='fs-1'>
                    {order?.currency?.symbol || '$'}
                    {Utils.toCurrency(row?.original?.totalAmt)} (
                    {order?.currency?.isoCode})
                  </div>

                  <Badge small variant={colors[status]} pilled>
                    {i18n(`v2.pages.studentDashboard.tabs.4.tabs.2.${status}`)}
                  </Badge>
                </div>
              </Flex>
              <div>
                {row.original?.status === 'PAID' ? (
                  <Button
                    className='mt-3'
                    block
                    variant='outline'
                    as={Link}
                    target='_blank'
                    to={PATHS.get('RECEIPT', {
                      receiptId: row?.original?.id
                    })}>
                    {i18n(
                      'v2.pages.organizationDashboard.tabs.4.buttons.download'
                    )}
                  </Button>
                ) : null}
                {row.original?.status === 'PENDING' ? (
                  <Button
                    disabled={hasFailedInstallment}
                    as={Link}
                    to={PATHS.get(
                      'PAY',
                      {
                        orderId: row?.original?.id
                      },
                      {
                        [QPARAMS.Draft]: true,
                        [QPARAMS.Redirect]: encodeURIComponent(
                          PATHS.get(
                            rootRoute,
                            { studentId },
                            { [QPARAMS.Tab]: 3 }
                          )
                        )
                      }
                    )}
                    block
                    className='mt-3'
                    variant={status === 'DUE' ? 'secondary' : 'primary'}>
                    {i18n(
                      'v2.pages.studentDashboard.tabs.4.tabs.2.buttons.pay'
                    )}
                  </Button>
                ) : null}
              </div>
            </div>
          );
        }
      }
    ],
    [
      hasFailedInstallment,
      order?.currency?.isoCode,
      order?.currency?.symbol,
      rootRoute,
      studentId
    ]
  );

  const load = useCallback(() => {
    if (order?.id) {
      setIsLoadingInstallments(true);

      getInstallments(order?.id)
        .then(({ data }: any) => {
          setInstallments(data);

          setPayAll(data.some((i: any) => i.status === 'PENDING'));

          setHavePending(
            data.some((i: any) => i.status === 'PENDING' && i.due)
          );
        })
        .finally(() => setIsLoadingInstallments(false));
    }
  }, [getInstallments, order?.id]);

  useEffect(() => {
    setOrder(orders.length ? orders[0] : null);
  }, [orders]);

  useEffect(() => {
    load();
  }, [load]);

  const lastInstallment = useMemo(() => {
    const pendings = installments.filter((i: any) => i.status === 'PENDING');

    return pendings.length ? pendings[pendings.length - 1] : null;
  }, [installments]);

  const lastDueInstallment = useMemo(() => {
    const pendings = installments.filter(
      (i: any) => i.status === 'PENDING' && i.due
    );

    return pendings.length ? pendings[pendings.length - 1] : null;
  }, [installments]);

  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <AutoComplete
            label={i18n(
              'v2.pages.studentDashboard.tabs.4.tabs.2.buttons.orderNumber'
            )}
            onChange={(e: any) => setOrder(e)}
            value={order}
            name='order'
            options={orders}
            loading={loading}
            disabled={orders.length === 0}
            customOption={AutoCompleteOption}
          />
        </Col>
        <Visible md lg xl>
          <Col className='text-right'>
            <Button
              variant='action'
              className=''
              loading={loading}
              circle
              icon={<Refresh fontSize='small' />}
              style={{ borderRadius: '8px' }}
              onClick={load}
            />
          </Col>
        </Visible>
      </Row>

      <Visible xs sm>
        <Table
          columns={mobileColumns}
          data={installments}
          loading={isLoadingInstallments || loading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          columns={desktopColumns}
          data={installments}
          loading={isLoadingInstallments || loading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Flex className='mt-4 | flex-column flex-md-row | justify-content-end'>
        {havePending ? (
          <Button
            as={Link}
            to={PATHS.get(
              'PAY',
              {
                orderId: lastDueInstallment?.id
              },
              {
                [QPARAMS.Draft]: true,
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(rootRoute, { studentId }, { [QPARAMS.Tab]: 3 })
                )
              }
            )}
            loading={isLoadingInstallments || loading}
            disabled={hasFailedInstallment}
            className='w-100 w-md-auto'
            variant='secondary'>
            {i18n('v2.pages.studentDashboard.tabs.4.tabs.2.buttons.payPending')}
          </Button>
        ) : null}

        {payAll ? (
          <Button
            as={Link}
            to={PATHS.get(
              'PAY',
              {
                orderId: lastInstallment?.id
              },
              {
                [QPARAMS.Draft]: true,
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(rootRoute, { studentId }, { [QPARAMS.Tab]: 3 })
                )
              }
            )}
            loading={isLoadingInstallments || loading}
            disabled={hasFailedInstallment}
            className='ml-0 ml-md-2 | mb-2 mb-md-0 | order-first order-md-last'>
            {i18n('v2.pages.studentDashboard.tabs.4.tabs.2.buttons.payAll')}
          </Button>
        ) : null}
      </Flex>
    </>
  );
}
