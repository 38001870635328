import { routes } from './routes';

export const PATHS = routes
  .filter((route) => !route.ignoreIntl)
  .reduce((accum: any, route) => {
    accum[route.key] = route.rootPath;
    return accum;
  }, {});

PATHS.get = (key: string, params: any = {}, search: any = {}) => {
  let path = PATHS[key];
  let queryString = '';

  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      path = path.replace(`:${param}`, params[param]);
    }
  }

  for (const q in search) {
    if (search.hasOwnProperty(q) && search[q] !== undefined) {
      queryString += `${q}=${search[q]}&`;
    }
  }

  return path + (queryString.length ? `?${queryString.slice(0, -1)}` : '');
};
