import { Footer, Navbar, Sidebar, WhatsappFabButton } from 'components';
import { Main } from 'dawere-uic';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { PageRenderer, RouteProps } from 'router';
import styled from 'styled-components';
import { getSchoolSetting } from 'utils';

const Grid = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'navbar'
    'main'
    'footer';
`;

/**
 * El layout por defecto de la web. Compuesto por:
 * - Navbar
 * - Contenido
 * - Footer
 */
export function DefaultLayout({
  component: Component,
  restricted,
  striped = false,
  inverse = false,
  ...rest
}: RouteProps) {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden auto';
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const View = (
          <Grid>
            {/* Sidebar */}
            <Sidebar />
            {/* See components/Nabvar */}
            <Navbar />
            {/* Main content */}
            <Main striped={striped} inverse={inverse}>
              <Component {...props} />
            </Main>
            {/* Whatsapp Fab Button */}
            <WhatsappFabButton link={getSchoolSetting('whatsappLink')} />
            {/* See components/Footer */}
            <Footer />
          </Grid>
        );

        return <PageRenderer view={View} restricted={restricted} />;
      }}
    />
  );
}
